import React, { useEffect, useState } from "react";
import styles from "../../Product.module.scss";
import { FormGroup, TextInput } from "src/components";
import TogglableArea from "src/pages/Product2/steps/DefineProduct/TogglableArea";

const ProductEmail = ({
  product,
  errors,
  setProduct,
}: {
  product: any;
  setProduct: React.Dispatch<React.SetStateAction<any>>;
  errors: any;
}) => {
  const [copied, setCopied] = useState(false);
  const [subdomain, setSubdomain] = useState("");
  const onChange = (key: string, value: string | boolean) => {
    setProduct({ ...product, [key]: value });
  };

  // Reset copied state after 2s
  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  return (
    <div className={styles.step}>
      <h1>Configure the email</h1>
      <p className={styles.desc}>
        {/* Get portal URL / show IDs in webhook data / Setup redirect back URL */}
        Change the content and URL of the emails we send to your customers when
        they interact with your products.
      </p>
      <div className={styles.form}>
        <TogglableArea
          title="Success email"
          subTitle="This is the email we send when your customers purchase your products"
        >
          <div className={styles.portalUrlContainer}>
            <FormGroup
              label="Email button URL"
              required
              error={errors.emailCallbackUrl}
            >
              <TextInput
                onChange={(e: any) =>
                  onChange("emailCallbackUrl", e.target.value)
                }
                value={product.emailCallbackUrl}
                error={errors.emailCallbackUrl}
                name="emailCallbackUrl"
                placeholder="Enter email callback URL"
              />
            </FormGroup>
            {/* <div
              className={styles.copyIcon}
              onClick={() => {
                navigator.clipboard.writeText(portalUrl || "");
                setCopied(true);
                toast.success("Subscription portal URL copied");
              }}
            >
              {copied ? <CheckCircle size={16} /> : <Clipboard size={16} />}
            </div> */}
          </div>
          <div className={styles.howToSection}>
            <h3>Important note:</h3>
            <p>
              To make the email route back to your systems with all the
              necessary data, we will add the following ID's:
              <code>productId</code>, <code>variantId</code> and{" "}
              <code>webhookId</code>.
              <br />
              <br />
              Here is an example of how it will look like:
              <br />
              <code>{`https://www.your-app.com/?productId=123&variantId=456&webhookId=789`}</code>
              <br />
              <br />
              If nothing is entered, then we will route to our Thank you page.
            </p>
            {/* show code block */}
          </div>
        </TogglableArea>
      </div>
    </div>
  );
};

export default ProductEmail;
