import React from "react";
import styles from "../../Discount.module.scss";
import {
  Button,
  CheckInput,
  ErrorBanner,
  FormGroup,
  Select,
  TextArea,
  TextInput,
} from "src/components";
import TogglableArea from "src/pages/Product2/steps/DefineProduct/TogglableArea";
import { DiscountType } from "../../Discount";
import { Link } from "react-router-dom";
import { formatCurrency } from "src/services/currency";
import { RefreshCcw, X } from "react-feather";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../Discount.scss";
import { makeCdnUrl } from "src/services/cdn";

const DefineDiscount = ({
  discount,
  setDiscount,

  errors,

  products,
}: {
  discount: DiscountType;
  setDiscount: React.Dispatch<React.SetStateAction<DiscountType>>;

  errors: any;

  products: any;
}) => {
  // useRef
  const ref = React.useRef<any>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const onChange = (key: string, value: any) => {
    setDiscount({ ...discount, [key]: value });
  };

  // handle click outside ref element
  React.useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open]);

  return (
    <div className={styles.step}>
      <h1>Set discount data</h1>
      <p className={styles.desc}>
        Add an discount name, generate a discount code, set the discount value
        as a percentage or fixed amount.
      </p>
      <div className={styles.form}>
        <FormGroup label="Name" error={errors.name} required>
          <TextInput
            onChange={(e: any) => onChange("name", e.target.value)}
            value={discount.name}
            name="name"
            placeholder="Enter discount name"
            error={errors.name}
          />
        </FormGroup>
        <TogglableArea
          title="Discount settings"
          subTitle="Set the discount code and value."
        >
          <FormGroup label="Code" error={errors.code} required>
            <TextInput
              onChange={(e: any) => onChange("code", e.target.value)}
              value={discount.code || ""}
              name="code"
              placeholder="Enter discount code"
              error={errors.code}
              rightIcon={
                <RefreshCcw
                  size={16}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    onChange(
                      "code",
                      Math.random().toString(36).substring(8).toUpperCase()
                    );
                  }}
                />
              }
            />
          </FormGroup>
          <div className={styles.formGrid}>
            <FormGroup label="Value" error={errors.value} required>
              <TextInput
                onChange={(e: any) => onChange("value", e.target.value)}
                value={discount.value}
                name="value"
                placeholder="Enter discount value"
                error={errors.value}
                type="number"
                isPercentage={discount.valueType === "percentage"}
                isCurrency={discount.valueType === "fixed"}
              />
            </FormGroup>
            <FormGroup label="Value type" error={errors.valueType} required>
              <Select
                options={[
                  { label: "Percentage", value: "percentage" },
                  { label: "Fixed amount", value: "fixed" },
                ]}
                onChange={(value) => onChange("valueType", value)}
                selectedValue={discount.valueType || ""}
                error={errors.valueType}
              />
            </FormGroup>
          </div>
          <FormGroup label="Expiration date" error={errors.expiration}>
            <div className={styles.dp} ref={ref}>
              <DatePicker
                selected={discount.expiration}
                onChange={(date: Date | null) => onChange("expiration", date)}
                open={open}
                customInput={
                  <div>
                    <TextInput
                      value={
                        discount.expiration
                          ? discount.expiration.toLocaleDateString()
                          : ""
                      }
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setOpen(!open);
                      }}
                      name="expiration"
                      onChange={() => {}}
                      placeholder="Select expiration date"
                      error={errors.expiration}
                      rightIcon={
                        discount.expiration && (
                          <X
                            size={20}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              onChange("expiration", null);
                            }}
                          />
                        )
                      }
                    />
                  </div>
                }
              />
            </div>
          </FormGroup>
        </TogglableArea>

        <TogglableArea
          title="Apply to products"
          subTitle="Select the products to which this discount will be applied."
        >
          {errors.mainProductIds && (
            <ErrorBanner message={errors.mainProductIds}></ErrorBanner>
          )}
          {products.length === 0 ? (
            <div className={styles.emptyList}>
              Either you don't have products, or all of them have upsells
              already
              <br />
              <br />
              {/* button to create new product */}
              <Link to="/products/new">
                <Button text="Create new product" />
              </Link>
            </div>
          ) : (
            <div>
              <div className={styles.selectAll}>
                <CheckInput
                  name="selectAll"
                  checked={discount.mainProductIds?.length === products.length}
                  onChange={(checked) => {
                    if (checked) {
                      onChange(
                        "mainProductIds",
                        products.map((product: any) => product._id)
                      );
                    } else {
                      onChange("mainProductIds", []);
                    }
                  }}
                  id={"selectAll"}
                />
                <label htmlFor="selectAll">Select all</label>
              </div>
              <div className={styles.productsList}>
                {products.map((product: any, index: any) => {
                  const prodActive =
                    discount.mainProductIds?.includes(product._id) || false;
                  return (
                    <div className={styles.productItem} key={index}>
                      <CheckInput
                        name="prodActive"
                        checked={prodActive}
                        onChange={(checked) => {
                          if (checked) {
                            onChange("mainProductIds", [
                              ...(discount.mainProductIds || []),
                              product._id,
                            ]);
                          } else {
                            onChange(
                              "mainProductIds",
                              discount.mainProductIds?.filter(
                                (id: any) => id !== product._id
                              )
                            );
                          }
                        }}
                        id={"prodActive" + index}
                      />
                      <Link
                        className={styles.productNameContainer}
                        to={`/products/${product._id}`}
                      >
                        <img
                          src={
                            product.thumbnail
                              ? makeCdnUrl(product.thumbnail)
                              : "https://dummyimage.com/1200&text=Product+Image"
                          }
                        />
                        <span className={styles.productName}>
                          {product.name}
                        </span>
                      </Link>
                      <div className={styles.productPrice}>
                        {formatCurrency(product.price)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </TogglableArea>
      </div>
    </div>
  );
};

export default DefineDiscount;
