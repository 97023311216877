import { makeCdnUrl } from "src/services/cdn";

export const getImageUrl = (image: string, demoMode?: boolean) => {
  if (demoMode) {
    return image || "https://via.placeholder.com/1200?text=Product+Image";
  }

  if (image) {
    return makeCdnUrl(image);
  } else {
    return "https://dummyimage.com/1200&text=Product+Image";
  }
};
