import React, { useEffect, useState } from "react";
import styles from "./Affiliate.module.scss";
import { PageTitle } from "src/components/PageTitle";
import { useNavigate, Link, useParams } from "react-router-dom";
import Axios from "src/services/api";
import {
  Button,
  Card,
  ErrorBanner,
  FormGroup,
  Modal,
  SwitchInput,
  TextInput,
} from "src/components";
import { formatCurrency } from "src/services/currency";
import { toast } from "react-hot-toast";
import usePopups from "src/services/usePopups";

const Affiliate: React.FC = () => {
  const navigate = useNavigate();
  const [products, setProducts] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingProducts, setLoadingProducts] = React.useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const { popups, togglePopup } = usePopups(["deleteAffiliate"]);

  const [errors, setErrors] = useState<any>({});

  const [email, setEmail] = useState<string>("");
  const [commission, setCommission] = useState<number>(0);
  const [active, setActive] = useState<boolean>(true);
  const [productIds, setProductIds] = useState<number[]>([]);
  const [mutating, setMutating] = useState<boolean>(false);
  const me = React.useMemo(
    () => localStorage.getItem("me") && JSON.parse(localStorage.getItem("me")!),
    [localStorage.getItem("me")]
  );

  useEffect(() => {
    if (id) {
      setLoading(true);
      toast.promise(
        Axios.get("/affiliates/" + id).then((res) => {
          setLoading(false);
          setEmail(res.data.affiliateUser);
          setCommission(res.data.commissionRate);
          setActive(res.data.active);
          setProductIds(res.data.productIds);
        }),
        // .catch((error) => {
        //   console.log(error);
        //   setLoading(false);
        // }),
        {
          loading: "Loading affiliate...",
          success: "Affiliate loaded!",
          error: () => {
            navigate("/affiliates");
            return "Failed to load affiliate";
          },
        }
      );
    }
  }, []);

  useEffect(() => {
    setLoadingProducts(true);
    Axios.get("/products").then((response) => {
      setProducts(response.data);
      setLoadingProducts(false);
    });
  }, []);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateForm()) return;

    setMutating(true);
    if (id) {
      Axios.post("/affiliates/" + id, {
        affiliateUser: email,
        commissionRate: commission,
        active,
        productIds,
      })
        .then(() => {
          setMutating(false);
          toast.success("Affiliate updated!");
        })
        .catch((error) => {
          console.log(error);
          setMutating(false);
          toast.error("Failed to update affiliate");
        });
    } else {
      Axios.post("/affiliates", {
        affiliateUser: email,
        commissionRate: commission,
        active,
        productIds,
      })
        .then((response) => {
          setMutating(false);
          toast.success("Affiliate created!");
          navigate("/affiliates/" + response.data._id);
        })
        .catch((error) => {
          console.log(error);
          setMutating(false);
          toast.error("Failed to create affiliate");
        });
    }
  };

  const validateForm = (): boolean => {
    let formErrors: any = {};

    if (!email) formErrors.email = "Select an affiliate user";
    if (commission < 0) formErrors.commission = "Commission should be positive";
    if (productIds.length === 0)
      formErrors.productIds = "Select at least one product";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  return (
    <form onSubmit={onSubmit}>
      <>
        {loading ? (
          <div></div>
        ) : (
          <>
            <PageTitle
              actions={
                <>
                  <Link to="/affiliates">
                    <Button text="Cancel" variant="secondary" />
                  </Link>
                  {id && (
                    <Button
                      text="Delete"
                      variant="danger"
                      onClick={() => {
                        togglePopup("deleteAffiliate");
                      }}
                    />
                  )}
                  <Button
                    text={id ? "Update affiliate" : "Save affiliate"}
                    loading={mutating}
                    type="submit"
                  />
                </>
              }
            >
              {id ? (
                <>
                  Affiliate for <u>{email}</u>
                </>
              ) : (
                "New Affiliate"
              )}
            </PageTitle>
            <div className={styles.pageContainer}>
              <div className={styles.left}>
                <Card>
                  <div className={styles.cardFromWrapper}>
                    <FormGroup label="Affiliate's email" error={errors.email}>
                      <TextInput
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter affiliate user email"
                      />
                    </FormGroup>

                    <FormGroup label="Commission" error={errors.commission}>
                      <TextInput
                        name="commission"
                        value={commission}
                        onChange={(e) =>
                          setCommission(e.target.value as unknown as number)
                        }
                        placeholder="Enter affiliate commission"
                        type="number"
                        isPercentage
                      />
                    </FormGroup>

                    <FormGroup label="Activate">
                      <SwitchInput
                        name="active"
                        checked={active}
                        onChange={(checked) => {
                          setActive(checked);
                        }}
                        id="active"
                      />
                    </FormGroup>
                  </div>
                </Card>
              </div>
              <div className={styles.right}>
                <Card title="Choose products">
                  {errors.productIds && (
                    <ErrorBanner message={errors.productIds}></ErrorBanner>
                  )}
                  <div className={styles.productsList}>
                    {products.map((product, index) => {
                      const prodActive = productIds.includes(product._id);
                      return (
                        <div className={styles.productItem} key={index}>
                          <SwitchInput
                            name="prodActive"
                            checked={prodActive}
                            onChange={(checked) => {
                              if (checked) {
                                setProductIds([...productIds, product._id]);
                              } else {
                                setProductIds(
                                  productIds.filter((id) => id !== product._id)
                                );
                              }
                            }}
                            id={"prodActive" + index}
                          />
                          <Link
                            className={styles.productNameContainer}
                            to={`/products/${product._id}`}
                          >
                            <img
                              src={
                                product.thumbnail ||
                                "https://dummyimage.com/1200&text=Product+Image"
                              }
                            />
                            <span className={styles.productName}>
                              {product.name}
                            </span>
                          </Link>
                          <div className={styles.productPrice}>
                            {formatCurrency(product.price)}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Card>
              </div>
            </div>
          </>
        )}
      </>
      {popups.deleteAffiliate && (
        <Modal
          onClose={() => togglePopup("deleteAffiliate")}
          title="Delete Affiliate"
          footerRightButton1={{
            label: "Cancel",
            onClick: () => {
              togglePopup("deleteAffiliate");
            },
          }}
          footerRightButton2={{
            variant: "danger",
            label: "Delete",
            onClick: () => {
              Axios.delete("/affiliates/" + id)
                .then(() => {
                  togglePopup("deleteAffiliate");
                  toast.success("Affiliate deleted!");
                  navigate("/affiliates");
                })
                .catch((error) => {
                  console.log(error);
                  togglePopup("deleteAffiliate");
                  toast.error("Failed to delete affiliate");
                });
            },
          }}
        >
          <div className={styles.deleteModalText}>
            Are you sure you want to delete this affiliate?
          </div>
        </Modal>
      )}
    </form>
  );
};

export default Affiliate;
