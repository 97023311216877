import React, { useEffect, useRef, useState } from "react";
import styles from "./Customers.module.scss";
import { PageTitle } from "src/components/PageTitle";
import { Link } from "react-router-dom";
import Axios from "src/services/api";
import dayjs from "dayjs";
import { Button, EmptyState, Modal, TextInput } from "src/components";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import Gravatar from "src/components/Gravatar";
import usePopups from "src/services/usePopups";
import { IoChevronDownOutline } from "react-icons/io5";
import { X } from "react-feather";
import { Product } from "src/models/product";
import { makeCdnUrl } from "src/services/cdn";
import { formatCurrency } from "src/services/currency";
import toast from "react-hot-toast";

const columns = ["Customer", "Country", "Orders", "Date created"];

interface CountryInfo {
  name: string;
  emoji: string;
  unicode: string;
  image: string;
}

export type Countries = Record<string, CountryInfo>;

const Customers: React.FC = () => {
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [products, setProducts] = useState<Product[]>([]);
  const [productId, setProductId] = useState<any>(undefined);
  const [openProduct, setOpenProduct] = useState(false);

  const refProduct = useRef<HTMLDivElement | null>(null);

  const { popups, togglePopup } = usePopups(["export"]);
  const navigate = useNavigate();
  const [customers, setCustomers] = React.useState<any[]>([]);
  const [filteredCustomers, setFilteredCustomers] = React.useState<any[]>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [search, setSearch] = React.useState<string>("");

  useEffect(() => {
    toast.promise(
      Axios.get(`/customers${productId ? `?productId=${productId}` : ""}`).then(
        (response) => {
          setCustomers(response.data);
          setLoading(false);
        }
      ),
      {
        error: "Error loading customers",
        loading: "Loading...",
        success: "Loaded customers",
      }
    );
  }, [productId]);

  useEffect(() => {
    if (search === "") {
      setFilteredCustomers(customers);
    } else {
      setFilteredCustomers(
        customers.filter((customer) => customer.buyerEmail.includes(search))
      );
    }
  }, [search, customers]);

  const toggleExportModal = () => {
    togglePopup("export");
  };

  const handleExport = () => {
    Axios.post(
      "/customers/export",
      {
        productId,
      },
      { responseType: "blob" }
    ).then((response) => {
      const blob = new Blob([response.data], {
        type: "text/csv;charset=utf-8",
      });
      saveAs(blob, "pf-customers.csv");
    });
  };

  const handleExportLast = () => {
    Axios.post(
      "/customers/export-since-last",
      {
        productId,
      },
      { responseType: "blob" }
    ).then((response) => {
      const blob = new Blob([response.data], {
        type: "text/csv;charset=utf-8",
      });
      saveAs(blob, "pf-customers.csv");
    });
  };

  const [countries, setCountries] = useState<Countries | null>(null);

  useEffect(() => {
    import("../../services/countries.json").then((data) => {
      setCountries(data.default as Countries);
    });
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        refProduct.current &&
        !refProduct.current.contains(event.target as Node)
      ) {
        setOpenProduct(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setLoadingProducts(true);
    Axios.get<Product[]>("/products")
      .then((response) => {
        setProducts(response.data);
        setLoadingProducts(false);
      })
      .catch(() => {
        setLoadingProducts(false);
      });
  }, []);

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className={styles.pageContainer}>
      <PageTitle
        actions={
          <div className={styles.searchInputContainer}>
            <div className={styles.productsFilter}>
              <div
                className={styles.productsToggler}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenProduct(!openProduct);
                }}
              >
                <span>
                  {!productId
                    ? "All products"
                    : products.find((product) => product._id === productId)
                        ?.name}{" "}
                </span>
                <div className={styles.iconContainer}>
                  {productId && (
                    <X
                      onClick={(e) => {
                        e.stopPropagation();
                        setProductId(null); // Clear the selected product
                      }}
                      size={18}
                      className={styles.clearIcon}
                    />
                  )}
                  <IoChevronDownOutline
                    style={{
                      marginLeft: 5,
                    }}
                    size={14}
                  />
                </div>
              </div>
              {openProduct && (
                <div className={styles.productsListContainer} ref={refProduct}>
                  <div className={styles.form}>
                    <div className={styles.label}>Filter by products</div>
                    <TextInput
                      name="search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="Search products..."
                      type="text"
                    />
                  </div>
                  <div className={styles.productsFilterList}>
                    {loadingProducts ? (
                      <div className={styles.loadingState}>
                        Loading products...
                      </div>
                    ) : products.length === 0 ? (
                      <div className={styles.emptyState}>
                        No products available.
                      </div>
                    ) : filteredProducts.length === 0 ? (
                      <div className={styles.emptySearchState}>
                        No products match your search.
                      </div>
                    ) : (
                      filteredProducts.map((product) => (
                        <div
                          className={styles.productsFilterItem}
                          onClick={() => {
                            setProductId(product._id);
                          }}
                          key={product._id}
                        >
                          <div className={styles.productNameContainer}>
                            <img
                              src={
                                product.thumbnail
                                  ? makeCdnUrl(product.thumbnail)
                                  : "https://dummyimage.com/1200&text=Product+Image"
                              }
                              alt={product.name}
                            />
                            <div className={styles.productName}>
                              {product.name}
                            </div>
                          </div>
                          <div className={styles.productPrice}>
                            {formatCurrency(product.price)}
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className={styles.seachInput}>
              <TextInput
                name="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search customers"
              />
            </div>
            {!loading &&
              // @ts-ignore
              filteredCustomers?.length > 0 && (
                <Button text="Export as CSV" onClick={toggleExportModal} />
              )}
          </div>
        }
      >
        Customers
      </PageTitle>
      {loading || filteredCustomers?.length === 0 ? (
        productId ? (
          <EmptyState
            title="No customers for this product"
            subTitle="It seems like this product doesn't have any customers yet. Share it with your audience to start making sales."
          />
        ) : (
          <EmptyState
            loading={loading}
            title="Get your first customer"
            subTitle="Create and share your products to make sales. Your customers will show up here."
          />
        )
      ) : (
        <>
          <div className={styles.tableContainer}>
            <table className={styles.productsTable}>
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th key={index}>{column}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredCustomers?.map((customer, index) => {
                  const country = countries?.[customer.country];
                  return (
                    <tr
                      key={index}
                      onClick={() => {
                        navigate(`/customers/${customer._id}`);
                      }}
                    >
                      <td style={{ width: "13%" }}>
                        <Gravatar email={customer.buyerEmail} size={100} />
                        <Link
                          className={styles.productNameContainer}
                          to={`/customers/${customer._id}`}
                        >
                          {customer.buyerEmail}
                        </Link>
                      </td>
                      <td>
                        <div>
                          {country?.emoji} {country?.name}
                        </div>
                      </td>
                      <td>
                        <div>{customer.numberOfOrders}</div>
                      </td>
                      <td>
                        <div>{dayjs(customer.createdAt).fromNow()}</div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Count items={filteredCustomers} />
        </>
      )}
      {/* <div className={styles.tableHeader}>
        {columns.map((column, index) => (
          <div key={index} className={styles.tableHeaderItem}>
            {column}
          </div>
        ))}
      </div> */}
      {popups.export && (
        <Modal
          onClose={() => togglePopup("export")}
          title={
            productId
              ? "Export customers for the selected product"
              : "Export customers"
          }
          footerRightButton2={{
            label: "Export customers",
            onClick: handleExport,
          }}
          footerRightButton1={{
            label: "Only customers since last export",
            onClick: handleExportLast,
          }}
        >
          <div
            className={`${styles.modalText} ${
              productId ? styles.expandModalText : ""
            }`}
          >
            Export all customers {productId ? "of this product" : ""} or just
            the ones that were added since your last export.
          </div>
        </Modal>
      )}
    </div>
  );
};

export const Count = ({ items }: any) => (
  <div className={styles.count}>
    {items.length > 0 && `${items.length}`} Results
  </div>
);

export default Customers;
